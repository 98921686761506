<script setup lang="ts">
import { RouterView } from 'vue-router'
import SnackbarComponent from './components/SnackbarComponent.vue';
import AuthService from './services/auth';
import WhatsAppButton from './components/WhatsAppButton.vue';
import { computed } from 'vue';

AuthService.listenUserData()

const hasWhatsAppSupportUrl = computed(() => import.meta.env.VITE_WHATSAPP_SUPPORT_URL?.length > 0);
</script>

<template>
  <SnackbarComponent />
  <RouterView />

  <WhatsAppButton v-if="hasWhatsAppSupportUrl" />
</template>
